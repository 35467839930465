import "./App.css";
import { useCountdown } from "./useCountDown";

function App() {
  const timeRemaining = useCountdown("2023-09-23");

  return (
    <div className="app">
      <div className="navbar">
        <div className="logo">
          <img src="simurg.svg" alt="simurg" />
          <div>Simurg Studio</div>
        </div>
        <div className="buttons">
          <a href="mailto:destek@simurg.studio" className="start">
            Start a Project
          </a>
          <a
            href="https://superpeer.com/erenolgun/-/lets-meet?s=d"
            target="_blank"
            className="schedule"
            rel="noreferrer"
          >
            Schedule a Call
          </a>
        </div>
      </div>

      <div className="hero">
        <div className="left">
          <div className="hero-text">
            We are preparing to <span>perfect</span> the standards
          </div>
          <div className="countdown">
            <div className="values">
              <div className="countdown-item">
                <div className="title">Day</div>
                <div className="value">
                  {("0" + timeRemaining[0]).slice(-2)}
                </div>
              </div>
              <div className="divider">:</div>
              <div className="countdown-item">
                <div className="title">Hour</div>
                <div className="value">
                  {("0" + timeRemaining[1]).slice(-2)}
                </div>
              </div>
              <div className="divider">:</div>

              <div className="countdown-item">
                <div className="title">Minute</div>
                <div className="value">
                  {("0" + timeRemaining[2]).slice(-2)}
                </div>
              </div>
              <div className="divider">:</div>

              <div className="countdown-item">
                <div className="title">Second</div>
                <div className="value">
                  {("0" + timeRemaining[3]).slice(-2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="soon.png" alt="coming-soon" />
        <div className="buttons buttons-hero">
          <a href="mailto:destek@simurg.studio" className="start">
            Start a Project
          </a>
          <a
            href="https://superpeer.com/erenolgun/-/lets-meet?s=d"
            target="_blank"
            className="schedule"
            rel="noreferrer"
          >
            Schedule a Call
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
